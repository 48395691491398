// FiraGo

// @import url('https://free.bboxtype.com/embedfonts/?family=FiraGO:200,300,500,600,700');

// font-family: 'FiraGO', sans-serif;
// ფონტის ერთ ხაზში გასწორება - font-feature-settings: "case" on;

// 200 - Light
// 300 - Book
// 500 - Medium
// 600 - Semi-Bold
// 700 - Bold

// FiraGo

@font-face {
	font-family: ubuntulight;
	src: url(./fonts/Ubuntu-Light.woff2) format("woff2"), url(./fonts/Ubuntu-Light.woff) format("woff");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}


@font-face {
	font-family: quadrosquare;
	src: url(./fonts/bpg_quadrosquare_mtavruli_2009.woff2) format("woff2"), url(./fonts/bpg_quadrosquare_mtavruli_2009.woff) format("woff");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}


@font-face {
	font-family: monserattlight;
	src: url(./fonts/MontserratLight.ttf);
}

@font-face {
	font-family: monserattbold;
	src: url(./fonts/MontserratBold.ttf);
}

@font-face {
	font-display: swap;
	font-family: nino;
	font-style: normal;
	font-weight: 400;
	src: url(./fonts/bpg_nino_mtavruli_normal.woff2) format("woff2"), url(./fonts/bpg_nino_mtavruli_normal.woff) format("woff")
}


@font-face {
	font-family: FiraGO-Bold;
	src: url(./fonts/FiraGO-Bold.ttf);
}

@font-face {
	font-family: FiraGO-Medium;
	src: url(./fonts/FiraGO-Medium.ttf);
}

@font-face {
	font-family: FiraGO-Book;
	src: url(./fonts/FiraGO-Book.ttf);
}


//translation
@font-face {
	font-family: segoe;
	src: url(./fonts/days.ttf)
}

@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap);
//translation


.App {
	font-family: nino
	
}

a {
	cursor: none !important;
	text-decoration: none;
}

li {
	list-style-type: none;
	display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	margin-top: 0;
	margin-bottom: 0;
	font-weight: normal;
}

button,
input[type="submit"],
input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	outline: inherit;
	cursor: none !important;
}

input[type="text"],
textarea {
	cursor: none;
}

.Flaker{
    position: absolute;
	width: 120%;
	height: 100%;
}

.MakeItSnow{
	display: flex;
	justify-content: space-between;
	margin: 0;
	padding: 0;
	overflow: hidden;
	height: 100%;
}

.Snowflake {
	display: inline-block;
	width: 0.1%;
	color: #FFFAFA;
	opacity: 0;
	font-size: 120px;
	margin: 0;
	padding: 0;
	animation: fall 60s linear infinite;
}

@keyframes fall {
	0% {
		opacity: 0;
	}
	3% {
		opacity: 0.9;
	}

	90% {
		opacity: 0.9;
	}
	100% {
		transform: translate(0, 100%);
	}
}

.header_cont{
	width: 100%;
	height: auto;
	margin-bottom: -8%;
	margin-left: -1%;
	position: relative;
	z-index: 15;
}

.header {
	width: 75%;
	height: 150px;
	display: flex;
	justify-content: center;
	align-items: center;
	zoom: 120%;
	white-space: nowrap;
	z-index: 10;
	font-weight: 300;
	font-family: "FiraGO-Book", sans-serif;
	font-feature-settings: "case" on;
	margin: auto;
}
.secondpage_fixer{
	width: 100%;
	height: 55px;
}
.social_box_bottom {
	display: flex;
	margin-top: 20px;
}

.header_logo {
	width: 200px;
	height: 45px;
	background-image: url(./images/logo.svg);
	background-size: 90%;
	background-position: center;
	background-repeat: no-repeat;
	position: absolute;
	left: 0px;
}

.header_logo_christmas {
	width: 200px;
	height: 55px;
	background-image: url(./images/christmaslogo.svg);
	background-size: 90%;
	background-position: center;
	background-repeat: no-repeat;
	position: absolute;
	left: 0px;
	margin-bottom: 10px;
}

.header_list {
	display: flex;
	justify-content: center;
	align-items: center;
}

.header_list_item1,
.header_list_item2,
.header_list_item3,
.header_list_item4,
.header_list_item5 {
	font-size: 17px;
	color: white;
	margin-left: 17px;
	margin-right: 17px;
	transition: 0.2s;

}

.header_list_item1:hover,
.header_list_item2:hover,
.header_list_item3:hover,
.header_list_item4:hover,
.header_list_item5:hover {
	color: #b4a6dd;
	transition: 0.2s;
}

.header_list_item_cont {
	margin-top: 8px;
}

.header_list_item_cont_dec1 {
	width: 0%;
	height: 3px;
	background-color: rgba(180, 166, 221, 0.059);
	margin: auto;
	margin-top: 5px;
	border-radius: 5px;
	transition: 0.5s;
}

.header_list_item_cont_dec2 {
	width: 0%;
	height: 3px;
	background-color: rgba(180, 166, 221, 0.059);
	margin: auto;
	margin-top: 5px;
	border-radius: 5px;
	transition: 0.3s;
}

.header_list_item_cont_dec3 {
	width: 0%;
	height: 3px;
	background-color: rgba(180, 166, 221, 0.059);
	margin: auto;
	margin-top: 5px;
	border-radius: 5px;
	transition: 0.3s;
}

.header_list_item_cont_dec4 {
	width: 0%;
	height: 3px;
	background-color: rgba(180, 166, 221, 0.059);
	margin: auto;
	margin-top: 5px;
	border-radius: 5px;
	transition: 0.3s;
}

.header_list_item_cont_dec5 {
	width: 0%;
	height: 3px;
	background-color: rgba(180, 166, 221, 0.059);
	margin: auto;
	margin-top: 5px;
	border-radius: 5px;
	transition: 0.3s;
}

.header_list_item1:hover:hover~.header_list_item_cont_dec1 {
	width: 60%;
	transition: 0.5s;
	background-color: #b4a6dd;
}

.header_list_item2:hover:hover~.header_list_item_cont_dec2 {
	width: 60%;
	transition: 0.5s;
	background-color: #b4a6dd;
}

.header_list_item3:hover:hover~.header_list_item_cont_dec3 {
	width: 60%;
	transition: 0.5s;
	background-color: #b4a6dd;
}

.header_list_item4:hover:hover~.header_list_item_cont_dec4 {
	width: 60%;
	transition: 0.5s;
	background-color: #b4a6dd;
}

.header_list_item5:hover:hover~.header_list_item_cont_dec5 {
	width: 60%;
	transition: 0.5s;
	background-color: #b4a6dd;
}

.header_language_item {
	font-size: 17px;
	color: white;
	margin-left: 10px;
	transition: 0.2s;
	font-weight: 500;
	font-family: 'FiraGO-Medium', sans-serif;
	font-feature-settings: "case" on;
}

.header_language_item:hover {
	color: #b4a6dd;
	transition: 0.2s;
}

.header_language {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	right: 0px;
	margin-top: -2px;
}

.firstpage {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.firstpage_right {
	width: 65%;
    height: 170%;
	background-image: url(./images/biglogo.svg);
    background-size: 70%;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    right: -36%;
    opacity: 0.05 !important;
}
.firstpage_left {
	width: 900px;
	height: 500px;
	margin-right: 600px;
	position: absolute;
	left: 0;
}
.firstpage_left_invisible{
	width: 900px;
	height: 500px;
	margin-right: 600px;	
	pointer-events: none !important;
}

.firstpage_container {
	margin-top: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 75%;
	position: relative;
}

.firstpage_left_text1 {
	font-size: 45px;
	color: #dcd1f9;
	font-family: ubuntulight;
	margin-bottom: 20px;
}

.firstpage_left_text2 {
	font-size: 65px;
	color: white;
	font-family: FiraGO-Bold;
	font-weight: 700;
	margin-bottom: 40px;
	font-feature-settings: "case" on;

}

.firstpage_left_decline {
	width: 100px;
	height: 6px;
	background-color: rgba(255, 255, 255, 0.215);
	margin-bottom: 30px;
}

.firstpage_left_text3 {
	font-size: 20px;
	color: #cdcdf2;
	margin-bottom: 40px;
	line-height: 28.8px;
	font-family: "FiraGO-Book", sans-serif;
	font-weight: 300;
}

.firstpage_left_button {
	width: 265px;
	height: 57px;
	border-radius: 30px;
	background-color: #9369fd;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 500;
	font-family: 'FiraGO-Medium', sans-serif;
	font-feature-settings: "case" on;
	font-size: 19px;
	transition: 0.5s;
	letter-spacing: 0.5px;
}

.firstpage_left_button:hover {
	background-color: white;
	color: black;
	transition: 0.5s;
}

.secondpage {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 30px;
}

.secondpage_item {
	height: 400px;
	width: 33.34%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.i1 {
	background-color: #44445a;
	grid-row: 1 / span 2;
}

.i2 {
	background-color: #8462dd;
}

.i3 {
	background-color: #2d2d5f;
}

.secondpage_item_container {
	width: 70%;
}

.secondpage_item_icon1 {
	width: 90px;
	height: 90px;
	background-image: url(./images/icon1.svg);
	background-size: 90%;
	background-repeat: no-repeat;
	background-position: center;
	margin: auto;
	margin-bottom: 50px;
}

.secondpage_item_icon2 {
	width: 90px;
	height: 90px;
	background-image: url(./images/icon2.svg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	margin: auto;
	margin-bottom: 50px;
}

.secondpage_item_icon3 {
	width: 90px;
	height: 90px;
	background-image: url(./images/icon3.svg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	margin: auto;
	margin-bottom: 50px;
}

.secondpage_text1 {
	text-align: center;
	font-size: 27px;
	font-weight: 500;
	font-family: 'FiraGO-Medium', sans-serif;
	font-feature-settings: "case" on;
	color: white;
	margin-bottom: 40px;
}

.secondpage_text2 {
	font-weight: 300;
	font-family: "FiraGO-Book", sans-serif;
	line-height: 28.8px;
	text-align: center;
	font-size: 20px;
	color: rgba(255, 255, 255, 0.81);
}

body::-webkit-scrollbar {
	width: 5px;
	border-radius: 5px;
	background-color: #2d2d5f;
}

body::-webkit-scrollbar-track {
	width: 5px;
	border-radius: 5px;
}

body::-webkit-scrollbar-thumb {
	background: #8462dd;
	width: 5px;
	border-radius: 5px;
}

.thirdpage {
	width: 100%;
	height: 100vh;
}

.thirdpage_about_container {
	margin: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	--animate-duration: 1.5s !important;
}

.thirdpage_about_container_firstdec {
	width: 130px;
	height: 60px;
	background-image: url(./images/firstdec.png);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	margin-top: 70px;
	margin-right: 90px;
}

.thirdpage_about_container_text {
	font-weight: 500;
	font-family: 'FiraGO-Medium', sans-serif;
	font-feature-settings: "case" on;
	color: white;
	font-size: 45px;
	letter-spacing: 4px;
}

.thirdpage_about_container_seconddec {
	width: 230px;
	height: 125px;
	background-image: url(./images/seconddec.png);
	background-position: center;
	background-size: 95%;
	background-repeat: no-repeat;
	margin-bottom: 70px;
	margin-left: 30px;
}

.thirdpage_container {
	height: 90%;
	position: relative;
	margin-top: 150px;
}
.thirdpage_list_fixer{
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	margin-top: 6%;
}
.thirdpage_header_fixer{
	display: block;
	position: relative;
	width: 100%;
	height: 20%;
}

.thirdpage_container_decimage {

	width: 60%;
    height: 110%;
	background-image: url(./images/biglogo.svg);
    background-size: 55%;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    left: -26%;
    bottom: -150px;
    opacity: 0.05 !important;
    --animate-duration: 2.5s;
}

.thirdpage_container_main {
	display: flex;
	margin-right: 150px;
	--animate-duration: 2.5s;
}

.thirdpage_container_main_left {
	width: 135px;
	height: 2px;
	background-color: #7c5ad6;
	margin-top: 30px;
	margin-right: 30px;
}

.thirdpage_container_main_right {
	width: 540px;
}

.thirdpage_container_main_right_header {
	font-size: 45px;
	color: white;
	font-family: monserattlight;
	font-weight: 500;
	letter-spacing: 8px;
	margin-bottom: 8px;
}

.thirdpage_container_main_right_desc {
	font-weight: 300;
	font-family: "FiraGO-Book", sans-serif;
	font-feature-settings: "case" on;
	font-size: 18px;
	color: rgba(255, 255, 255, 0.5);
	line-height: 24px;
	margin-top: 20px;
}

.thirdpage_container_banner {
	position: relative;
	width: 800px;
	height: 430px;
	--animate-duration: 2.5s;
}

.thirdpage_container_banner_inner {
	width: 800px;
	height: 430px;
	background-color: #19192d;
	border-radius: 10px;
	box-shadow: 0px 3px 21px 0px #00000061;
	background-image: url(./images/logo.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: 65%;
	z-index: 3 !important;
	position: absolute;
}

.thirdpage_container_banner_dec1 {
	width: 900px;
	height: 200px;
	z-index: 2;
	border: 3px solid #393d50;
	position: absolute;
	bottom: -30px;
	left: -30px;
}

.thirdpage_container_banner_dec2 {
	width: 900px;
	height: 200px;
	z-index: 1;
	border: 3px solid #272b46;
	position: absolute;
	bottom: 30px;
	left: -60px;
}

.thirdpage_container_fixer {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: -400px;
}

.fourthpage {
	background-color: #19192d;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 7%;
    padding-top: 8%;
}

.fourthpage_top_header {
	font-weight: 500;
	font-family: 'FiraGO-Medium', sans-serif;
	font-feature-settings: "case" on;
	color: white;
	font-size: 45px;
	letter-spacing: 4px;
	margin: auto;
	text-align: center;
	margin-top: 40px;
}

.fourthpage_top_head_container {
	margin: auto;
	position: relative;
	width: 640px;
}

.fourthpage_top_decline {
	width: 90px;
	height: 2px;
	background-color: rgba(255, 255, 255, 0.541);
	margin: auto;
	margin-top: 60px;
}

.fourthpage_top_desc {
	text-align: center;
	font-size: 16px;
	color: #cdcdf2;
	max-width: 650px;
	margin-top: 40px !important;
	line-height: 24px;
	margin: auto;
	font-weight: 300;
	font-family: "FiraGO-Book", sans-serif;
}

.fourthpage_top {
	position: relative;
}

.fourthpage_top_decimg {
	height: 170px;
	width: 100px;
	background-image: url(./images/thirddec.png);
	background-size: 80%;
	background-repeat: no-repeat;
	position: absolute;
	top: -95px;
	left: -30px;
}

.fourthpage_bot {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 140px;
	margin-left: 90px;
}

.fourthpage_bot_item {
	max-width: 340px;
	height: 240px;
	position: relative;
	z-index: 3;
	margin: 70px;
	margin-bottom: 0px !important;
}

.fourthpage_bot_item_num1 {
	z-index: 1;
	font-family: monserattbold;
	color: rgba(255, 255, 255, 0.751);
	font-size: 100px;
	position: absolute;
	opacity: 0.1;
	left: -30px;
	top: -65px;
}

.fourthpage_bot_item_decline {
	width: 130px;
	height: 1px;
	background-color: rgba(255, 255, 255, 0.541);
	text-align: center;
	margin-top: 25px;
	margin-bottom: 25px;
	z-index: 3;
	position: relative;
}

.fourthpage_bot_item_desc {
	text-align: left;
	font-size: 15px;
	color: rgba(255, 255, 255, 0.755);
	line-height: 24px;
	z-index: 3;
	position: relative;
	font-family: nino;
	font-weight: 300;
	font-family: "FiraGO-Book", sans-serif;
	font-feature-settings: "case" on;
}

.fourthpage_bot_item_header {
	font-size: 18px;
	color: white;
	font-family: quadrosquare;
	text-align: left;
	letter-spacing: 2px;
	z-index: 3;
	position: relative;
	font-weight: 500;
	font-family: 'FiraGO-Medium', sans-serif;
	font-feature-settings: "case" on;
}

.fourthpage_top_head_container {
	margin: auto;
}

.fourthpage_bot_color {
	color: #8462dd !important;
}

.fourthpage_bot_bgcolor {
	background-color: #8462dd !important;
}

.fifthpage {
	width: 100%;
	height: 155vh;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 6%;
    padding-top: 6%;
}

.fifthpage_container {
	width: 100%;
}

.fifthpage_projects_more {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	right: 0;
}

.fifthpage_projects_more_text {
	color: #cdcdf2;
	margin-right: 20px;
	font-weight: 500;
	font-family: "FiraGO-Medium", sans-serif;
	font-feature-settings: "case" on;
}

.fifthpage_projects_more_button {
	width: 268px;
	height: 53px;
	border-radius: 15px;
	background-color: #9369fd;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	zoom: 90%;
	font-weight: 500;
	font-family: "FiraGO-Medium", sans-serif;
	font-feature-settings: "case" on;
	font-size: 18px;
	transition: 0.5s;
}

.fifthpage_projects_more_button:hover {
	background-color: white;
	color: black;
	transition: 0.5s;
}

.fifthpage_projects_container {
	width: 90%;
	height: 110vh;
	margin: auto;
	margin-top: 80px;
	position: relative;
}

.fifthpage_projects_top {
	display: flex;
	width: 100%;
	height: 53%;
}

.fifthpage_projects_down {
	display: flex;
	width: 100%;
	height: 47%;
	margin-top: 20px;
}

.fifthpage_projects_top_item1 {
	width: 45%;
	height: 100%;
	background-image: url(./images/portfolio/mountaincart.png);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	transition: 0.5s;
}

.fifthpage_projects_top_item1:hover {
	transform: scale(1.03);
	transition: 0.5s;
}

.fifthpage_projects_top_item2_cont {
	width: 27.5%;
	height: 100%;
	margin-left: 20px;
	position: relative;
}

.fifthpage_projects_top_item2_first {
	width: 100%;
	height: 48%;
	background-image: url(./images/portfolio/megadrive.png);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	transition: 0.5s;
	display: block;
}

.fifthpage_projects_top_item2_first:hover {
	transform: scale(1.03);
	transition: 0.5s;
}

.fifthpage_projects_top_item2_second {
	width: 100%;
	height: 48%;
	position: absolute;
	bottom: 0;
	background-image: url(./images/portfolio/bestshoes.png);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	transition: 0.5s;
}

.fifthpage_projects_top_item2_second:hover {
	transform: scale(1.03);
	transition: 0.5s;
}

.fifthpage_projects_top_item3 {
	width: 25%;
	height: 100%;
	margin-left: 20px;
	background-image: url(./images/portfolio/history.png);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	transition: 0.5s;
}

.fifthpage_projects_top_item3:hover {
	transform: scale(1.03);
	transition: 0.5s;
}

.fifthpage_projects_down_left {
	width: 55.7%;
	height: 95%;
	background-image: url(./images/portfolio/stalinurisiebi.png);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	transition: 0.5s;
}

.fifthpage_projects_down_left:hover {
	transform: scale(1.03);
	transition: 0.5s;
}

.fifthpage_projects_down_right {
	width: 43%;
	margin-left: 20px;
	height: 95%;
	background-image: url(./images/portfolio/jigarosbox.png);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	transition: 0.5s;
}

.fifthpage_projects_down_right:hover {
	transform: scale(1.03);
	transition: 0.5s;
}

.fifthpage_projects_more {
	display: flex;
}

.fifthpage_header {
	font-weight: 500;
	font-family: 'FiraGO-Medium', sans-serif;
	font-feature-settings: "case" on;
	color: white;
	font-size: 45px;
	letter-spacing: 4px;
	margin-bottom: 130px;
	text-align: center;
}

.fifthpage_slider {
	width: 90%;
	margin-left: 50px;
	float: right;
}

.fifthpage_slider_item {
	width: 576;
	height: 576px !important;
	position: relative;
	display: flex !important;
	justify-content: center;
	align-items: center;
}

.fifthpage_slider_item_shadow {
	width: 100%;
	height: 100%;
	background: rgb(0, 0, 0);
	background: linear-gradient(87deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 100%);
	z-index: 3;
	margin-right: 4px;
	position: absolute;
}

.fifthpage_slider_item_bg {
	width: 100%;
	height: 100%;
	position: absolute;
	opacity: 1;
	filter: blur(1px);
}

.fifthpage_slider_item_name {
	font-size: 60px;
	color: white;
	font-family: monserattbold;
	z-index: 4;
	position: relative;
	margin-left: -100px;
	max-width: 500px;
	word-break: break-word;
	margin-bottom: 50px;
	width: 65%;
	text-shadow: 2px 2px 4px #000000;
}

.fifthpage_slider_item_button {
	width: 250px;
	height: 65px;
	border: 3px solid white;
	border-radius: 35px;
	position: absolute;
	z-index: 4;
	bottom: 50px;
	left: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	color: white;
	font-size: 20px;
	font-family: quadrosquare;
	zoom: 80%;
	transition: 0.5s;
}

.fifthpage_top {
	margin-top: -5%;
}

.fifthpage_top_header {
	color: white;
	font-size: 45px;
	letter-spacing: 4px;
	text-align: center;
	margin-bottom: 35px;
	font-weight: 500;
	font-family: 'FiraGO-Medium', sans-serif;
	font-feature-settings: "case" on;
}

.fifthpage_top_decline {
	width: 90px;
	height: 2px;
	background-color: rgba(255, 255, 255, 0.541);
	margin-top: 60px !important;
	margin: auto;
}

.fifthpage_top_desc {
	font-size: 16px;
	color: #cdcdf2;
	max-width: 650px;
	line-height: 24px;
	text-align: center;
	margin: auto;
	font-weight: 300;
	font-family: "FiraGO-Book", sans-serif;
}

.fifthpage_slider_item_button:hover {
	background-color: white;
	transition: 0.5s;
	color: black;
}

.sixthpage {
	width: 100%;
	height: 80vh;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 3%;
    padding-top: 3%;
}

.sixthpage_container {
	width: 100%;
}

.sixthpage_top_header {
	color: white;
	font-size: 45px;
	letter-spacing: 4px;
	text-align: center;
	margin-bottom: 35px;
	font-weight: 500;
	font-family: 'FiraGO-Medium', sans-serif;
	font-feature-settings: "case" on;
}

.sixthpage_top_decline {
	width: 90px;
	height: 2px;
	background-color: rgba(255, 255, 255, 0.541);
	margin-top: 40px !important;
	margin-bottom: 40px !important;
	margin: auto;
}

.sixthpage_top_desc {
	font-size: 16px;
	color: #cdcdf2;
	max-width: 650px;
	line-height: 24px;
	text-align: center;
	margin-top: 60px;
	margin: auto;
	font-weight: 300;
	font-family: "FiraGO-Book", sans-serif;
}

.sixthpage_slider_item {
	width: 220px !important;
	height: 220px !important;
	margin-left: 15px;
	margin-right: 15px;
	border-radius: 20px;
}

.sixthpage_slider {
	margin-top: 150px !important;
	margin: auto;
	width: 70%;
}

.seventhpage {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #19192d;
}

.seventhpage_top_header {
	color: white;
	font-size: 45px;
	letter-spacing: 4px;
	text-align: center;
	margin-bottom: 35px;
	font-weight: 500;
	font-family: 'FiraGO-Medium', sans-serif;
	font-feature-settings: "case" on;
}

.seventhpage_top_decline {
	width: 90px;
	height: 2px;
	background-color: rgba(255, 255, 255, 0.541);
	margin: auto;
}

.seventhpage_top_desc {
	font-size: 16px;
	color: #cdcdf2;
	max-width: 650px;
	margin-top: 40px !important;
	line-height: 24px;
	text-align: center;
	margin: auto;
	font-weight: 300;
	font-family: "FiraGO-Book", sans-serif;
}

.seventhpage_bot {
	position: relative;
	margin-bottom: 200px;
}

.seventhpage_bot_container {
	position: relative;
	width: 500px;
	margin: auto;
}

.seventhpage_bot_button {
	width: 280px;
	height: 70px;
	border-radius: 35px;
	background-color: #9369fd;
	zoom: 75%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	color: white;
	font-weight: 500;
	font-family: 'FiraGO-Medium', sans-serif;
	font-feature-settings: "case" on;
	margin: auto;
	position: relative;
	z-index: 2;
	letter-spacing: 1px;
	transition: 0.5s;
}

.seventhpage_bot_button:hover {
	background-color: white;
	color: black;
	transition: 0.5s;
}

.seventhpage_bot_dec {
	width: 250px;
	height: 190px;
	background-image: url(./images/fourthdec.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: 90%;
	position: absolute;
	z-index: 1 !important;
	left: 100px;
	top: -80px;
}

.seventhpage_middle {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	margin-bottom: 150px !important;
	margin-top: 125px !important;
	width: 85%;
	margin: auto;
}

.seventhpage_middle_item {
	width: 360px;
	height: 440px;
	border: 1px solid rgba(255, 255, 255, 0.5);
	margin: 20px;
	border-bottom: 6px solid #9369fd !important;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 0.5s;
}

.seventhpage_middle_item:hover {
	background-color: #9369fd74;
	transition: 0.5s;
	border: 1px solid #9369fd;
}

.seventhpage_middle_item_container {
	display: block;
	width: 70%;
	height: 85%;
	position: relative;
}

.seventhpage_top {
	margin-top: 100px;
}

.servicepage_top {
	margin-top: 100px;
}

.seventhpage_middle_item_avatar {
	width: 128px;
	height: 128px;
	border-radius: 50%;
	object-fit: cover;
	border: 2px solid #ffffff03;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	transition: 0.3s;
}

.seventhpage_middle_item:hover .seventhpage_middle_item_avatar {
	border: 2px solid #9369fd;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	transition: 0.3s;
}

.seventhpage_middle_item_name {
	font-size: 23px;
	color: white;
	font-family: monserattlight;
	margin-top: 10px;
}

.seventhpage_middle_item_job {
	color: #a4a4fb;
	font-size: 17px;
	margin-top: 30px;
}

.seventhpage_middle_item_desc {
	font-size: 13px;
	color: #cdcdf2;
	margin-top: 20px;
}

.seventhpage_team_button {
	width: 130px;
	height: 35px;
	background-color: #9369fd;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	border-radius: 6px;
	font-weight: 500;
	font-family: 'FiraGO-Medium', sans-serif;
	font-feature-settings: "case" on;
	zoom: 90%;
	position: absolute;
	bottom: 0;
}

.servicepage {
	width: 100%;
	background-color: #1f1f3b;
}

.servicepage_container {
	width: 82%;
	margin: auto;
	margin-top: 150px;
	margin-bottom: 150px;
}

.servicepage_item {
	border: 1px solid #4c4c6f;
	width: 85%;
	height: 40vh;
	border-radius: 12px;
	margin-top: 40px !important;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	margin: auto;
}

.servicepage_item_hidden {
	height: 100.4%;
	width: 36%;
	margin-left: -1px;
}

.servicepage_item_image1,
.servicepage_item_image2,
.servicepage_item_image3 {
	height: 100.4%;
	width: 36%;
	background-image: url(./images/webdev.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: absolute;
	left: -1px;
	border-radius: 12px 0px 0px 12px;
}

.servicepage_item_image2 {
	background-image: url(./images/designserv.png);
}

.servicepage_item_image3 {
	background-image: url(./images/1cserv.png);
}

.servicepage_item_infocontainer {
	width: 55%;
	height: 80%;
	position: relative;
	zoom: 95%;
}

.servicepage_item_infocontainer_title {
	color: white;
	font-size: 28px;
	font-weight: 700;
	letter-spacing: 2px;
	font-family: 'FiraGO-Medium', sans-serif;
	margin-bottom: 15px;
}

.servicepage_item_infocontainer_smalltitle {
	color: #cdcdf2;
	font-size: 20px;
	font-family: 'FiraGO-Medium', sans-serif;
	font-feature-settings: "case" on;
	font-weight: 500;

}

.servicepage_item_infocontainer_decline {
	width: 200px;
	height: 1px;
	background-color: #8361dd;
	margin-top: 20px;
	margin-bottom: 20px;
}

.servicepage_item_infocontainer_desc {
	color: #ededed;
	opacity: 0.65;
	font-size: 17px;
	font-family: 'FiraGO-Medium', sans-serif;
	font-weight: 300;
	height: 45%;
	overflow: scroll;
}

.servicepage_item_infocontainer_desc::-webkit-scrollbar {
	display: none;
}

.servicepage_item_infocontainer_button {
	width: 201px;
	height: 49px;
	border-radius: 15px;
	background-color: #9369fd;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	font-size: 20px;
	font-family: 'FiraGO-Medium', sans-serif;
	font-feature-settings: "case" on;
	font-weight: 500;
	position: absolute;
	bottom: 0;
	transition: 0.5s;
}

.servicepage_item_infocontainer_button:hover {
	color: black;
	background-color: white;
	transition: 0.5s;
}

.mainfooter {
	width: 100%;
	background-color: #12121d;
}

.mainfooter_drop {
	height: 100px;
	width: 100%;
	background-color: #181828;
	border-top: 1px solid rgba(255, 255, 255, 0.195);
}

.mainfooter_footer {
	height: 449px;
}

.mainfooter_drop {
	display: flex;
	justify-content: center;
	align-items: center;
}

.mainfooter_drop_container {
	height: 70%;
	width: 70%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.mainfooter_drop_container_copyright {
	font-family: monserattlight;
	font-size: 14px;
	color: rgba(255, 255, 255, 0.612);
	position: absolute;
	left: 0px;
}

.mainfooter_drop_container_socials {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	right: 0px;

}

.mainfooter_drop_container_socials_item1 {
	width: 20px;
	height: 20px;
	fill: #ffffff !important;
	background-image: url(./images/instagram.svg);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	zoom: 80%;
	opacity: 0.7;
	transition: 0.3s;
}

.mainfooter_drop_container_socials_item1:hover {
	opacity: 1;
	transition: 0.3s;
}

.mainfooter_drop_container_socials_item2:hover {
	opacity: 1;
	transition: 0.3s;
}

.mainfooter_drop_container_socials_item3:hover {
	opacity: 1;
	transition: 0.3s;
}

.mainfooter_drop_container_socials_item4:hover {
	opacity: 1;
	transition: 0.3s;
}

.mainfooter_drop_container_socials_item2 {
	width: 20px;
	height: 20px;
	fill: #ffffff !important;
	background-image: url(./images/facebook.svg);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	zoom: 80%;
	margin-left: 10px;
	opacity: 0.7;
	transition: 0.3s;
}

.mainfooter_drop_container_socials_item3 {
	width: 20px;
	height: 20px;
	fill: #ffffff !important;
	background-image: url(./images/linkedin.svg);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	zoom: 80%;
	margin-left: 10px;
	opacity: 0.7;
	transition: 0.3s;
}

.mainfooter_drop_container_socials_item4 {
	width: 25px;
	height: 20px;
	fill: #ffffff !important;
	background-image: url(./images/youtube.svg);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	zoom: 80%;
	margin-left: 10px;
	margin-top: 3px;
	opacity: 0.7;
	transition: 0.3s;
}

.mainfooter_drop_container_middle {
	display: flex;
	justify-content: center;
	align-items: center;
}

.mainfooter_drop_container_middle_item {
	font-size: 0.875em;
	color: rgba(255, 255, 255, 0.477);
	letter-spacing: 0.5px;
	transition: 0.5s;
	font-weight: 500;
	font-family: 'FiraGO-Medium', sans-serif;
	font-feature-settings: "case" on;
	letter-spacing: 1.5px;
}

.mainfooter_drop_container_middle_item:hover {
	transition: 0.5s;
	color: #9369fd;
}

.mainfooter_drop_container_middle_item_decline {
	height: 14px;
	width: 1px;
	background-color: rgba(255, 255, 255, 0.273);
	margin-left: 15px;
	margin-right: 15px;
}

.mainfooter_footer {
	display: flex;
	justify-content: center;
	align-items: center;
}

.mainfooter_footer_contact {
	margin-right: 200px;
}

.mainfooter_footer_pages {
	margin-right: 200px;
}

.mainfooter_footer_container {
	display: flex;
	position: relative;
}

.mainfooter_footer_contact_header,
.mainfooter_footer_pages_header,
.mainfooter_footer_news_header {
	font-size: 1.125em;
	color: #feffff;
	font-weight: 500;
	font-family: 'FiraGO-Medium', sans-serif;
	font-feature-settings: "case" on;
	letter-spacing: 0.7px;
	margin-bottom: 40px;
}

.mainfooter_footer_contact_item,
.mainfooter_footer_pages_item,
.mainfooter_footer_news_item {
	display: flex;
	align-items: center;
	margin-top: 20px;
}

.mainfooter_footer_contact_item_text,
.mainfooter_footer_pages_item_text,
.mainfooter_footer_news_item_text {
	font-size: 1em;
	font-family: quadrosquare;
	color: rgba(255, 255, 255, 0.6);
	transition: 0.5s;
}

.mainfooter_footer_pages_item_text:hover {
	transition: 0.5s;
	color: #9369fd;
}

.mainfooter_footer_news_item_text {
	max-width: 250px;
	font-size: 0.875em;
}

.mainfooter_footer_contact_item_icon1 {
	width: 19px;
	height: 19px;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url(./images/call.svg);
	margin-right: 20px;
	opacity: 0.6;
}

.mainfooter_footer_contact_item_icon2 {
	width: 19px;
	height: 19px;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url(./images/email.svg);
	margin-right: 20px;
	opacity: 0.6;
}

.mainfooter_footer_contact_item_icon3 {
	width: 19px;
	height: 19px;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url(./images/instagram.svg);
	margin-right: 20px;
	opacity: 0.6;
}

.mainfooter_footer_contact_item_icon4 {
	width: 19px;
	height: 19px;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url(./images/facebook.svg);
	margin-right: 20px;
	opacity: 0.6;
}

.mainfooter_footer_news_input_container {
	display: flex;
	position: relative;
	margin-top: 40px;
	zoom: 90%;
}

.mainfooter_footer_news_input {
	background-color: #181828;
	outline: none;
	border: 1px solid #394050;
	border-radius: 3px;
	width: 200px;
	height: 40px;
	padding-left: 20px;
	color: white;
	cursor: none;
	padding-right: 10px;
	font-family: quadrosquare;
}

.mainfooter_footer_news_input::placeholder {
	color: rgba(255, 255, 255, 0.308) !important;
	font-family: quadrosquare;
	letter-spacing: 0.5px;
}

.mainfooter_footer_news_input_button {
	width: 44px;
	height: 44px;
	border-radius: 0px 3px 3px 0px;
	background-color: #394050;
	margin-left: -4px;
	background-image: url(./images/arrow.svg);
	background-position: center;
	background-repeat: no-repeat;
}

.mainfooter_footer_goback {
	height: 100%;
	margin-left: 340px;
}

.mainfooter_footer_goback_item {
	width: 50px;
	height: 50px;
	transform: rotate(-90deg);
	background-image: url(./images/arrow.svg);
	background-position: center;
	background-repeat: no-repeat;
	background-color: rgba(0, 0, 0, 0.397);
	border-radius: 4px;
	position: absolute;
	bottom: 0;
	opacity: 0.5;
	transition: 0.5s;
}

.mainfooter_footer_goback_item:hover {
	transition: 0.5s;
	opacity: 1;
}

.header_list_burger {

	display: none;
	right: 0;
	position: absolute;
	margin-top: -6px;
}


.header_list_burger_active {
    z-index: 999;
    position: absolute;
    left: 4px;
    top: -30px;
	zoom: 120%;
}

.header_list_burger_active_item {
	display: block;
	margin: 10px;
	text-align: left;
	padding-top: 5px;
	padding-right: 5px;
	color: white;
	transition: 0.4s;
	font-size: 30px;
	margin-top: 30px;
}

.header_list_burger_active_item:hover {
	color: #9369fd;
	transition: 0.4s;
}

.header_list_burger_active_langcont {
	margin-top: 30px;
	height: 20px;
	color: white;
	display: flex;
	align-items: center;
}

.header_list_burger_active_item_lan {
	color: white;
	background-color: #19192dd4;
	margin-right: 10px;
	padding-left: 10px !important;
	padding-right: 10px !important;
	padding: 5px;
	border-radius: 4px;
	transition: 0.4s;
	zoom: 150%;
	margin-left: 5px;
}

.header_list_burger_active_item_lan:hover {
	background-color: #9369fd;
	transition: 0.4s;
}

.seohidden {
	visibility: hidden;
	position: absolute;
	pointer-events: none;
}

.header_list_noburger {

	display: flex;
	justify-content: center;
	align-items: center;
}

.an1 {
	--animate-delay: 0.3s !important;
}

.an2 {
	--animate-delay: 0.6s !important;
}

.an3 {
	--animate-delay: 0.9s !important;
}

.socialpage {
	width: 100%;
	height: 90vh;
	display: flex;
	justify-content: center;
	align-items: center;
	// position: relative;
	background-color: #19192d;
	overflow: hidden;
	padding-bottom: 6%;
    padding-top: 6%;
}

.social_image {
	width: 100%;
	height: 100vh;
	background-image: url(./images/contback.png);
	background-size: cover;
	background-position: right;
	background-repeat: no-repeat;
	position: absolute;
	zoom: 112%;
	padding-bottom: 4%;
    padding-top: 4%;
}

.social_container {
	width: 79%;
	display: flex;
	align-items: center;
}

.social_leftside {
	zoom: 90%;
}

.social_rightside {
	zoom: 90%;
}

.social_header_name {
	font-size: 45px;
	color: #f0f3f2;
	margin-bottom: 30px;
	font-weight: 500;
	font-family: 'FiraGO-Medium', sans-serif;
	font-feature-settings: "case" on;
	display: inline-block;
}

.social_header_name_form {
	font-size: 45px;
	color: #f0f3f2;
	margin-bottom: 60px;
	font-weight: 500;
	font-family: 'FiraGO-Medium', sans-serif;
	font-feature-settings: "case" on;
	display: inline-block;
	text-align: right;
	width: 100%;
}

.social_header_decline {
	width: 90px;
	height: 1px;
	background-color: #a1a1a1;
	margin-bottom: 30px;
}

.social_form_container {
	width: 100%;
	height: 525px;
	margin-top: 190px;
	position: relative;
	z-index: 2;
	margin-left: 25px;
}

.social_form_first_cont {
	display: flex;
}

.social_form_firstinput {
    width: 40.8%;
	height: 60px;
	background-color: #252543;
	border: none;
	outline: none;
	margin-left: 2.2%;
	color: white;
	padding-left: 20px;
	padding-right: 20px;
	font-size: 16px;
	font-weight: 500;
	font-family: 'FiraGO-Medium', sans-serif;
}

.social_form_third_button {
	width: 95%;
	height: 53px;
	border-radius: 15px;
	background-color: #9369fd;
	margin-left: 15px;
	margin-top: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	font-weight: 500;
	font-family: 'FiraGO-Medium', sans-serif;
	font-feature-settings: "case" on;
	font-size: 20px;
	transition: 0.5s;
}

.social_form_third_button_success{
	width: 95%;
	height: 53px;
	border-radius: 15px;
	background-color: #3AE24B;
	margin-left: 15px;
	margin-top: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: black;
	font-weight: 500;
	font-family: 'FiraGO-Medium', sans-serif;
	font-feature-settings: "case" on;
	font-size: 20px;
	transition: 0.5s;
}

.social_form_third_button_error{
	width: 95%;
	height: 53px;
	border-radius: 15px;
	background-color: #F2263F;
	margin-left: 15px;
	margin-top: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	font-weight: 500;
	font-family: 'FiraGO-Medium', sans-serif;
	font-feature-settings: "case" on;
	font-size: 20px;
	transition: 0.5s;
}

.social_form_third_button:hover {
	background-color: white;
	color: black;
	transition: 0.5s;
}

.social_form_secondinput {
	width: 89.3%;
	height: 60px;
	margin-top: 16px;
	background-color: #252543;
	border: none;
	outline: none;
	margin-left: 16px;
	color: white;
	padding-left: 20px;
	padding-right: 20px;
	font-size: 16px;
	font-weight: 500;
	font-family: 'FiraGO-Medium', sans-serif;
}

.social_form_thirdinput {
	width: 89.3% !important;
	height: 270px !important;
	margin-top: 16px;
	background-color: #252543;
	border: none;
	outline: none;
	margin-left: 16px;
	color: white;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 20px;
	font-size: 16px;
	font-weight: 500;
	font-family: 'FiraGO-Medium', sans-serif;
	resize: none;
}

.social_header_decline_form {
	width: 90px;
	height: 1px;
	background-color: #a1a1a1;
	margin-top: -30px;
	float: right;
}

.social_header_desc {
	max-width: 80%;
	font-size: 18px;
	color: #cdcdf2;
	font-weight: 300;
	font-family: "FiraGO-Book", sans-serif;
	line-height: 30px;
}

.social_header_desc_form {
	font-size: 18px;
	color: #cdcdf2;
	display: block;
	font-weight: 300;
	font-family: "FiraGO-Book", sans-serif;
	line-height: 30px;
	text-align: right;
	width: 100%;
}

.social_box {
	margin-top: 150px;
}

.social_box_top {
	display: flex;
}

.social_box_item {
	width: 270px;
	height: 300px;
	background-color: #1f1f3b;
	border-radius: 15px;
	margin-right: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 0.3s;
	zoom: 90%;
}

.social_box_item_phone {
	width: 850px;
	height: 200px;
	background-color: #1f1f3b;
	border-radius: 15px;
	margin-right: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 0.3s;
	zoom: 90%;
	margin-top: 20px;
}

.social_box_item_phone:hover {
	background-color: #9369fd;
	transition: 0.3s;
}

.social_box_item:hover {
	background-color: #9369fd;
	transition: 0.3s;
}

.social_box_item_container {
	font-family: "Nunito";
}

.social_box_item_container_phone {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.social_box_item_icon,
.social_box_item_icon2,
.social_box_item_icon3,
.social_box_item_icon4 {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	background: linear-gradient(90deg, rgba(1, 186, 211, 1) 0%, rgba(122, 204, 237, 1) 100%);
	margin: auto;
}

.social_box_item_phone_right {
	margin-left: 30%;
}

.social_box_item_ctitle {
	text-align: center;
	font-size: 12px;
	color: rgba(255, 255, 255, 0.507);
}

.social_box_item_ctitle_phone {
	text-align: center;
	font-size: 18px;
	color: rgba(255, 255, 255, 0.507);
}

.sico1,
.sico2,
.sico3,
.sico4 {
	width: 100%;
	height: 100%;
	background-image: url(./images/social/mail.png);
	background-position: center;
	background-size: 50%;
	background-repeat: no-repeat;
}

.sico2 {
	background-image: url(./images/social/facebook.png);
	background-size: 55%;
}

.sico3 {
	background-image: url(./images/social/instagram.png);
}

.sico4 {
	background-image: url(./images/social/phone.png);
}

.social_box_item_icon2 {
	background: linear-gradient(90deg, rgba(49, 158, 243, 1) 0%, rgba(41, 64, 242, 1) 100%);
}

.social_box_item_icon3 {
	background: linear-gradient(90deg, rgba(140, 49, 243, 1) 0%, rgba(178, 41, 242, 1) 100%);
}

.social_box_item_icon4 {
	background: linear-gradient(90deg, rgba(243, 49, 104, 1) 0%, rgba(202, 60, 84, 1) 100%);
}

.social_box_item_title {
	font-size: 22px;
	color: white;
	text-align: center;
	margin-top: 30px;
}

.social_box_item_title_phone {
	font-size: 30px;
	color: white;
	text-align: center;
}

.social_box_item_desc {
	font-size: 17px;
	color: rgba(255, 255, 255, 0.905);
	text-align: center;
	margin-top: 30px;
}

.social_box_item_desc_phone {
	font-size: 26px;
	color: rgba(255, 255, 255, 0.905);
	text-align: center;
	margin-top: 20px;
}

#nav_icon3 span:nth-child(1) {
	top: 0px;
}

#nav_icon3 span:nth-child(2),
#nav_icon3 span:nth-child(3) {
	top: 18px;
}

#nav_icon3 span:nth_child(4) {
	top: 36px;
}

#nav_icon3.open span:nth_child(1) {
	top: 18px;
	width: 0%;
	left: 50%;
}

#nav_icon3.open span:nth_child(2) {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
}

#nav_icon3.open span:nth_child(3) {
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

#nav_icon3.open span:nth-child(4) {
	top: 18px;
	width: 0%;
	left: 50%;
}


@media only screen and (max-width: 3840px) {

	// 3840x2160
	.header {
		zoom: 250%;
	}

	.firstpage_container {
		zoom: 189%;
	}

	.secondpage {
		zoom: 200%;
	}

	.thirdpage_container {
		zoom: 200%;
	}

	.fourthpage_container {
		zoom: 200%;
	}

	.fifthpage_container {
		zoom: 200%;
	}

	.fifthpage_projects_container {
		zoom: 50%;
	}

	.fifthpage_projects_more {
		zoom: 200%;
		margin-right: 12px;
	}

	.social_container {
		zoom: 200%;
	}

	.servicepage_item_infocontainer {
		zoom: 200%;
	}

	.servicepage_top {
		zoom: 200%;
	}

	.sixthpage_container {
		zoom: 200%;
	}

	.seventhpage_container {
		zoom: 200%;
	}

	.mainfooter {
		zoom: 200%;
	}

}

@media only screen and (max-width: 2560px) {

	// 2560x1440 , 2560x1600
	.header {
		zoom: 170%;
	}

	.firstpage_container {
		zoom: 126%;
	}

	.secondpage {
		zoom: 125%;
	}

	.fifthpage {
		height: 190vh;
	}

	.fifthpage_container {
		zoom: 100%;
	}

	.fifthpage_projects_container {
		zoom: 100%;
	}

	.thirdpage_container {
		zoom: 125%;
	}

	.fourthpage_container {
		zoom: 125%;
	}

	.fifthpage_container {
		zoom: 125%;
	}

	.fifthpage_projects_more {
		zoom: 100%;
		margin-right: 0px;
	}

	.social_container {
		zoom: 125%;
	}

	.servicepage_item_infocontainer {
		zoom: 125%;
	}

	.servicepage_top {
		zoom: 125%;
	}

	.sixthpage_container {
		zoom: 125%;
	}

	.seventhpage_container {
		zoom: 125%;
	}

	.mainfooter {
		zoom: 125%;
	}
}

@media only screen and (max-width: 2100px) {
	.header {
		zoom: 120%;
	}

	.firstpage_container {
		zoom: 100%;
	}

	.secondpage {
		zoom: 100%;
	}

	.thirdpage_container {
		zoom: 100%;
	}

	.fourthpage_container {
		zoom: 100%;
	}

	.fifthpage_container {
		zoom: 100%;
	}

	.fifthpage {
		height: 155vh;
	}

	.social_container {
		zoom: 100%;
	}

	.servicepage_item_infocontainer {
		zoom: 100%;
	}

	.servicepage_top {
		zoom: 100%;
	}

	.sixthpage_container {
		zoom: 100%;
	}

	.seventhpage_container {
		zoom: 100%;
	}

	.mainfooter {
		zoom: 100%;
	}
}

@media only screen and (max-width: 1920px) {

	// 1920x1080
	.header {
		zoom: 120%;
	}

	.firstpage_container {
		zoom: 95%;
	}

	.secondpage {
		zoom: 100%;
	}

	.thirdpage_container {
		zoom: 100%;
	}

	.fourthpage_container {
		zoom: 100%;
	}

	.fifthpage_container {
		zoom: 100%;
	}

	.fifthpage {
		height: 155vh;
	}

	.fifthpage_projects_container {
		zoom: 100%;
	}

	.social_container {
		zoom: 100%;
	}

	.socialpage{
		height: 90vh;
	}

	.servicepage_item_infocontainer {
		zoom: 100%;
	}

	.servicepage_top {
		zoom: 100%;
	}

	.sixthpage{
		height: 80vh;
	}

	.sixthpage_container {
		zoom: 100%;
	}

	.seventhpage_container {
		zoom: 100%;
	}

	.mainfooter {
		zoom: 100%;
	}
}

@media only screen and (max-width: 1600px) {

	//1600x900 , 1536x864
	.header {
		zoom: 100%;
	}

	.firstpage_container {
		zoom: 78%;
	}

	.secondpage {
		zoom: 80%;
	}

	.thirdpage_container {
		zoom: 80%;
	}

	.fourthpage_container {
		zoom: 80%;
	}

	.fifthpage_container {
		zoom: 80%;
	}

	.fifthpage {
		height: 125vh;
	}

	.social_container {
		zoom: 80%;
	}

	.servicepage_item_infocontainer {
		zoom: 80%;
	}

	.servicepage_top {
		zoom: 80%;
	}

	.sixthpage_container {
		zoom: 80%;
	}

	.seventhpage_container {
		zoom: 80%;
	}

	.mainfooter {
		zoom: 80%;
	}
}

@media only screen and (max-width: 1440px) {

	//1440x900
	.header {
		zoom: 90%;
	}

	.firstpage_container {
		zoom: 70%;
	}

	.secondpage {
		zoom: 70%;
	}

	.thirdpage_container {
		zoom: 70%;
	}

	.fourthpage_container {
		zoom: 80%;
	}

	.fifthpage_container {
		zoom: 70%;
	}

	.fifthpage {
		height: 115vh;
	}

	.socialpage{
		height: 80vh;
	}

	.social_container {
		zoom: 70%;
	}

	.servicepage_item_infocontainer {
		zoom: 65%;
	}

	.servicepage_top {
		zoom: 70%;
	}

	.sixthpage_container {
		zoom: 80%;
	}

	.seventhpage_container {
		zoom: 70%;
	}

	.mainfooter {
		zoom: 70%;
	}
}

@media only screen and (max-width: 1380px) {

	//1366x768 , 1280x720
	.header {
		zoom: 80%;
	}

	.firstpage_container {
		zoom: 67%;
	}

	.secondpage {
		zoom: 65%;
	}

	.thirdpage_container {
		zoom: 65%;
	}

	.fourthpage_container {
		zoom: 75%;
	}

	.fifthpage_container {
		zoom: 65%;
	}

	.social_container {
		zoom: 65%;
	}

	.sixthpage_container {
		zoom: 75%;
	}

	.seventhpage_container {
		zoom: 65%;
	}

	.mainfooter {
		zoom: 65%;
	}
}

@media only screen and (max-width: 1024px) {

	//1024x768
	.header {
		zoom: 70%;
	}

	.firstpage_container {
		zoom: 65%;
	}

	.firstpage_right {
		background-size: 60%;
	}

	.secondpage {
		zoom: 55%;
	}

	.thirdpage_container {
		zoom: 55%;
	}

	.fourthpage_container {
		zoom: 60%;
	}

	.fifthpage_container {
		zoom: 55%;
	}

	.fifthpage {
		height: 100vh;
	}

	.social_container {
		zoom: 55%;
	}

	.servicepage_item_infocontainer {
		zoom: 50%;
	}

	.servicepage_top {
		zoom: 60%;
	}

	.servicepage_item {
		height: 30vh;
	}

	.servicepage_container {
		margin-top: 100px;
	}

	.sixthpage_container {
		zoom: 65%;
	}

	.seventhpage_container {
		zoom: 60%;
	}

	.mainfooter {
		zoom: 60%;
	}
}

@media only screen and (max-width:1024px) and (min-height: 800px) {

	//ipads
	.fourthpage_bot {
		margin-left: 0px;
	}

	.header_list_burger {
		display: block;
	}

	.header_list_noburger {
		display: none;
	}

	.header {
		zoom: 60%;
	}

	.firstpage {
		height: 50vh;
	}

	.firstpage_container {
		zoom: 70%;
	}

	.firstpage_left {
		margin-right: 250px;
	}

	.firstpage_right {
		background-size: 50%;
	}

	.secondpage {
		zoom: 60%;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 1fr);
	}

	.i1 {
		grid-row: 1 / span 1;
	}

	.i3 {
		grid-column: 1 / span 2;
	}

	.secondpage_item {
		width: 100%;
	}

	.secondpage_item_container {
		padding: 120px;
	}

	.thirdpage {
		height: 40vh;
	}

	.thirdpage_container {
		zoom: 50%;
	}

	.thirdpage_container_banner {
		margin-bottom: -400px;
	}

	.fourthpage_container {
		zoom: 65%;
	}

	.fourthpage_bot {
		flex-wrap: wrap;
	}

	.fourthpage_bot_item {
		max-width: 500px;
	}

	.fifthpage{
		height: 70vh;
	}


	.fifthpage_container {
		zoom: 40%;
	}

	.socialpage {
		height: 50vh;
	}


	.social_container {
		zoom: 60%;
	}

	.fifthpage_slider_item {
		zoom: 100%;
	}

	.sixthpage {
		height: 50vh;
	}

	.sixthpage_container {
		zoom: 70%;
	}

	.sixthpage_slider {
		zoom: 75%;
	}

	.seventhpage_container {
		zoom: 50%;
	}

	.mainfooter {
		zoom: 50%;
	}

	.mainfooter_footer_container {
		zoom: 80%;
	}

	.servicepage_item_infocontainer {
		zoom: 60%;
	}

	.servicepage_item {
		height: 20vh;
	}

	.servicepage_container {
		margin-top: 60px;
		width: auto;
	}
}

@media only screen and (max-width: 850px) {

	//800x600
	.header {
		zoom: 50%;
	}

	.firstpage_container {
		zoom: 45%;
	}

    .firstpage{
		height: 50vh;
	}

	.firstpage_right{
		background-size: 75%;
	}

	.secondpage {
		zoom: 40%;
	}

	.thirdpage{
		height: 45vh;
	}

	.thirdpage_container {
		zoom: 40%;
	}

	.fourthpage_container {
		zoom: 45%;
	}

	.fifthpage_container {
		zoom: 45%;
	}


	.fifthpage_projects_container {
		zoom: 120%;
	}

	.social_container {
		zoom: 45%;
	}

	.servicepage_item_infocontainer {
		zoom: 45%;
	}

	.servicepage_top {
		zoom: 50%;
	}

	.servicepage_item {
		height: 32vh;
	}

	.servicepage_container {
		margin-top: 70px;
	}


	.sixthpage_container {
		zoom: 50%;
	}

	.seventhpage_container {
		zoom: 50%;
	}

	.mainfooter {
		zoom: 50%;
	}
}

@media only screen and (max-width:540px) {

	//surface Duo
	.fifthpage_slider_item_button {
		zoom: 100%;
	}

	.fourthpage_bot {
		margin-left: 0px;
	}

	body {
		cursor: auto !important;
	}

	.app {
		cursor: auto !important;
	}

	a {
		cursor: auto !important;
	}

	html {
		cursor: auto !important;
	}

	.header_list_burger {
		display: block;
	}

	.header_list_noburger {
		display: none;
	}

	.header {
		zoom: 50%;
	}

	.firstpage {
		height: 40vh;
	}

	.firstpage_right{
		background-size: 90%;
	}

	.firstpage_container {
		zoom: 38%;
	}

	.servicepage_item_infocontainer {
		zoom: 27%;
	}

	.servicepage_top {
		zoom: 50%;
	}

	.servicepage_item {
		height: 13vh;
	}

	.servicepage_container {
		margin-top: 60px;
		width: auto;
		margin-bottom: 50px;
	}

	.thirdpage_container_fixer {
		margin-top: -80px;
	}

	.socialpage {
		height: auto;
		padding-top: 50px;
		padding-bottom: 50px;
	}

	.social_rightside{
		margin: auto !important;
		margin-bottom: 100px !important;
		margin-right: 0px;
		margin-left: 0px;
		margin-top: 100px !important
	}

	.social_container {
		zoom: 38%;
	}

	.firstpage_left {
		margin-right: 250px;
	}


	.secondpage {
		zoom: 40%;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 1fr);
	}

	.i1 {
		grid-row: 1 / span 1;
	}

	.i3 {
		grid-column: 1 / span 2;
	}

	.secondpage_item {
		width: 100%;
	}

	.secondpage_item_container {
		padding: 120px;
	}

	.thirdpage {
		height: 60vh;
	}

	.thirdpage_container {
		zoom: 40%;
	}

	.thirdpage_container_banner {
		margin-bottom: -400px;
	}

	.fourthpage_container {
		zoom: 45%;
	}

	.fourthpage_bot {
		flex-wrap: wrap;
	}

	.fourthpage_bot_item {
		max-width: 500px;
	}

	.fifthpage_container {
		zoom: 40%;
	}

	.fifthpage_slider_item {
		zoom: 75%;
	}

	.fifthpage {
		height: auto;
		padding-top: 100px;
		padding-bottom: 100px;
	}

	.fifthpage_projects_container {
		zoom: 100%;
		height: 70vh;
	}

	.sixthpage {
		height: 60vh;
	}

	.sixthpage_container {
		zoom: 50%;
	}

	.sixthpage_slider {
		zoom: 75%;
	}

	.seventhpage_container {
		zoom: 50%;
	}

	.mainfooter {
		zoom: 40%;
	}

	.mainfooter_footer_container {
		zoom: 80%;
	}
}

@media only screen and (max-width:480px) {
	.header_list_burger {
		display: block;
	}

	.header_list_noburger {
		display: none;
	}

	.header {
		zoom: 50%;
	}

	.firstpage {
		height: 40vh;
	}

	.firstpage_container {
		zoom: 38%;
	}

	.firstpage_left {
		margin-right: 0px;
	}

	.firstpage_right {
		background-size: 90%;
	}

	.secondpage {
		zoom: 40%;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 1fr);
	}

	.i1 {
		grid-row: 1 / span 1;
	}

	.i3 {
		grid-column: 1 / span 2;
	}

	.secondpage_item {
		width: 100%;
	}

	.secondpage_item_container {
		padding: 120px;
		zoom: 80%;
	}

	.thirdpage {
		height: 60vh;
	}

	.thirdpage_container {
		zoom: 40%;
		margin-left: -100px;
	}

	.thirdpage_container_decimage {
		display: none;
	}

	.thirdpage_container_fixer {
		display: block;
		margin: auto;
		margin-top: 300px;
	}

	.thirdpage_container_main_left {
		transform: rotate(-90deg);
		margin-top: 80px;
	}

	.thirdpage_container {
		margin-left: 0px;
	}

	.thirdpage_container_main {
		margin-right: 0px;
		margin-bottom: 100px;
	}

	.thirdpage_container_banner {
		margin-bottom: 0px;
	}

	.fourthpage {
		height: 80vh;
	}

	.fourthpage_container {
		zoom: 45%;
	}

	.fourthpage_bot {
		flex-wrap: wrap;
	}

	.fourthpage_bot_item {
		max-width: 500px;
	}


	.social_leftside {
		margin-bottom: 100px;
		margin-right: 0px;
		margin-left: 0px;
	}

	.social_header_name {
		text-align: center;
		width: 100%;
	}

	.social_header_decline {
		margin: auto;
	}

	.social_header_desc {
		text-align: center;
		max-width: 100%;
		margin-top: 30px;
	}

	.social_header_name_form {
		text-align: center;
		width: 100%;
		margin-bottom: 25px;
	}

	.social_form_container {
		margin-top: 60px;
		margin-left: 10px;
	}

	.social_header_decline_form {
		margin: auto !important;
		float: none;
	}

	.social_header_desc_form {
		text-align: center;
		max-width: 100%;
		margin-top: 30px;
	}

	.social_container {
		zoom: 50%;
		flex-wrap: wrap;
	}

	.social_box {
		flex-wrap: wrap;
		flex-basis: 50%;
		margin: auto !important;
		margin-top: 50px !important;
		max-width: fit-content;
	}

	.social_box_item {
		margin-top: 15px;
	}

	.fifthpage_container {
		zoom: 40%;
	}

	.fifthpage_slider_item {
		zoom: 60%;
	}

	.sixthpage {
		height: 45vh;
	}

	.sixthpage_container {
		zoom: 50%;
	}

	.sixthpage_slider {
		zoom: 60%;
	}

	.seventhpage_container {
		zoom: 50%;
	}

	.seventhpage_middle_item {
		zoom: 80%;
	}

	.mainfooter {
		zoom: 40%;
	}

	.mainfooter_footer_container {
		zoom: 65%;
	}

	.mainfooter_drop_container {
		zoom: 90%;
	}
}

@media only screen and (max-width:431px) {
	.header_list_burger {
		display: block;
	}

	.header_list_noburger {
		display: none;
	}

	.header {
		zoom: 50%;
	}

	.firstpage {
		height: 35vh;
	}

	.firstpage_right{
		background-size: 90%;
	}

	.firstpage_container {
		zoom: 38%;
	}

	.firstpage_left {
		margin-right: 0px;
	}

	.secondpage {
		zoom: 40%;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 1fr);
	}

	.i1 {
		grid-row: 1 / span 1;
	}

	.i3 {
		grid-column: 1 / span 2;
	}

	.secondpage_item {
		width: 100%;
	}

	.secondpage_item_container {
		padding: 120px;
		zoom: 80%;
	}

	.thirdpage {
		height: 60vh;
	}

	.thirdpage_container {
		zoom: 40%;
		margin-left: -100px;
	}

	.thirdpage_container_decimage {
		display: none;
	}

	.thirdpage_container_fixer {

		margin-top: -80px;
	}

	.thirdpage_container_main_left {
		transform: rotate(-90deg);
		margin-top: 80px;
	}

	.thirdpage_container {
		margin-left: 0px;
	}

	.thirdpage_container_main {
		margin-right: 0px;
		margin-bottom: 100px;
	}

	.thirdpage_container_banner {
		margin-bottom: 0px;
	}

	.fourthpage {
		height: 85vh;
	}

	.fourthpage_container {
		zoom: 45%;
	}

	.fourthpage_bot {
		flex-wrap: wrap;
	}

	.fourthpage_bot_item {
		max-width: 500px;
	}

	.fifthpage_container {
		zoom: 40%;
	}

	.social_container {
		zoom: 40%;
	}

	.social_box {
		flex-wrap: wrap;
		margin-top: 50px;
	}

	.social_box_item {
		margin-top: 15px;
	}

	.fifthpage_slider_item {
		zoom: 60%;
	}

	.sixthpage {
		height: 40vh;
	}

	.sixthpage_container {
		zoom: 45%;
	}

	.sixthpage_slider {
		zoom: 60%;
	}

	.seventhpage_container {
		zoom: 50%;
	}

	.seventhpage_middle_item {
		zoom: 80%;
	}

	.mainfooter {
		zoom: 30%;
	}

	.mainfooter_footer_container {
		zoom: 80%;
	}

	.mainfooter_drop_container {
		zoom: 100%;
	}

}

@media only screen and (max-width:380px) {
	.header_list_burger {
		display: block;
	}

	.header_list_noburger {
		display: none;
	}

	.header {
		zoom: 50%;
	}

	.firstpage {
		height: 55vh;
	}

	.firstpage_container {
		zoom: 38%;
	}

	.firstpage_left {
		margin-right: 0px;
	}

	.firstpage_right {
		background-size: 90%;
	}

	.secondpage {
		zoom: 40%;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 1fr);
	}

	.i1 {
		grid-row: 1 / span 1;
	}

	.i3 {
		grid-column: 1 / span 2;
	}

	.secondpage_item {
		width: 100%;
	}

	.secondpage_item_container {
		padding: 120px;
		zoom: 80%;
	}

	.thirdpage {
		height: 80vh;
	}

	.thirdpage_container {
		zoom: 40%;
		margin-left: -100px;
	}

	.thirdpage_container_decimage {
		display: none;
	}

	.thirdpage_container_main_left {
		transform: rotate(-90deg);
		margin-top: 80px;
	}

	.thirdpage_container {
		margin-left: 0px;
	}

	.thirdpage_container_main {
		margin-right: 0px;
		margin-bottom: 100px;
	}

	.thirdpage_container_banner {
		margin-bottom: 0px;
	}

	.fourthpage {
		height: 100vh;
	}

	.fourthpage_container {
		zoom: 45%;
	}

	.fourthpage_bot {
		flex-wrap: wrap;
	}

	.fourthpage_bot_item {
		max-width: 500px;
	}

	.fifthpage_container {
		zoom: 40%;
	}

	.social_container {
		zoom: 40%;
	}

	.social_box {
		flex-wrap: wrap;
		margin-top: 50px;
	}

	.social_box_item {
		margin-top: 15px;
	}

	.fifthpage_slider_item {
		zoom: 50%;
	}

	.sixthpage {
		height: 50vh;
	}

	.sixthpage_container {
		zoom: 40%;
	}

	.sixthpage_slider {
		zoom: 60%;
	}

	.seventhpage_container {
		zoom: 50%;
	}

	.seventhpage_middle_item {
		zoom: 70%;
	}

	.mainfooter {
		zoom: 30%;
	}

	.mainfooter_footer_container {
		zoom: 65%;
	}

	.mainfooter_drop_container {
		zoom: 90%;
	}

	.servicepage_item_infocontainer {
		zoom: 24%;
	}

	.servicepage_top {
		zoom: 50%;
	}

	.servicepage_item {
		height: 16vh;
	}

	.servicepage_container {
		margin-top: 60px;
		width: auto;
		margin-bottom: 50px;
	}
}

@media only screen and (max-width:400px) {
	.header_list_burger {
		display: block;
	}

	.header_list_noburger {
		display: none;
	}

	.header {
		zoom: 50%;
	}

	.firstpage {
		height: 45vh;
	}

	.firstpage_right{
		background-size: 90%;
	}

	.firstpage_container {
		zoom: 38%;
	}

	.firstpage_left {
		margin-right: 0px;
	}

	.secondpage {
		zoom: 40%;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 1fr);
	}

	.i1 {
		grid-row: 1 / span 1;
	}

	.i3 {
		grid-column: 1 / span 2;
	}

	.secondpage_item {
		width: 100%;
	}

	.secondpage_item_container {
		padding: 120px;
		zoom: 80%;
	}

	.thirdpage {
		height: 80vh;
	}

	.thirdpage_container {
		zoom: 40%;
		margin-left: -100px;
	}

	.thirdpage_container_decimage {
		display: none;
	}

	.thirdpage_container_fixer {
		margin-top: -80px;
	}

	.thirdpage_container_main_left {
		transform: rotate(-90deg);
		margin-top: 80px;
	}

	.thirdpage_container {
		margin-left: 0px;
	}

	.thirdpage_container_main {
		margin-right: 0px;
		margin-bottom: 100px;
	}

	.thirdpage_container_banner {
		margin-bottom: 0px;
	}

	.fourthpage {
		height: 100vh;
	}

	.fourthpage_container {
		zoom: 45%;
	}

	.fourthpage_bot {
		flex-wrap: wrap;
	}

	.fourthpage_bot_item {
		max-width: 500px;
	}

	.fifthpage_container {
		zoom: 40%;
	}

	.social_container {
		zoom: 42%;
	}

	.social_box {
		flex-wrap: wrap;
		margin-top: 50px;
	}

	.social_box_item {
		margin-top: 15px;
	}

	.fifthpage_slider_item {
		zoom: 50%;
	}

	.sixthpage {
		height: 50vh;
	}

	.sixthpage_container {
		zoom: 40%;
	}

	.sixthpage_slider {
		zoom: 60%;
	}

	.seventhpage_container {
		zoom: 50%;
	}

	.seventhpage_middle_item {
		zoom: 70%;
	}

	.mainfooter {
		zoom: 30%;
	}

	.mainfooter_footer_container {
		zoom: 65%;
	}

	.mainfooter_drop_container {
		zoom: 90%;
	}
}


@media only screen and (max-width:380px) {
	.header_list_burger {
		display: block;
	}

	.header_list_noburger {
		display: none;
	}

	.header {
		zoom: 50%;
	}


	.firstpage_container {
		zoom: 38%;
	}

	.firstpage_left {
		margin-right: 0px;
	}

	.secondpage {
		zoom: 40%;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 1fr);
	}

	.i1 {
		grid-row: 1 / span 1;
	}

	.i3 {
		grid-column: 1 / span 2;
	}

	.secondpage_item {
		width: 100%;
	}

	.secondpage_item_container {
		padding: 120px;
		zoom: 80%;
	}

	.thirdpage {
		height: 80vh;
	}

	.thirdpage_container {
		zoom: 40%;
		margin-left: -100px;
	}

	.thirdpage_container_decimage {
		display: none;
	}

	.thirdpage_container_main_left {
		transform: rotate(-90deg);
		margin-top: 80px;
	}

	.thirdpage_container {
		margin-left: 0px;
	}

	.thirdpage_container_main {
		margin-right: 0px;
		margin-bottom: 100px;
	}

	.thirdpage_container_banner {
		margin-bottom: 0px;
	}

	.fourthpage {
		height: 100vh;
	}

	.fourthpage_container {
		zoom: 45%;
	}

	.fourthpage_bot {
		flex-wrap: wrap;
	}

	.fourthpage_bot_item {
		max-width: 500px;
	}


	.fifthpage_container {
		zoom: 40%;
	}

	.social_container {
		zoom: 35%;
	}

	.social_box {
		flex-wrap: wrap;
		margin-top: 50px;
	}

	.social_box_item {
		margin-top: 15px;
	}

	.fifthpage_slider_item {
		zoom: 50%;
	}

	.sixthpage {
		height: 50vh;
	}

	.sixthpage_container {
		zoom: 40%;
	}

	.sixthpage_slider {
		zoom: 60%;
	}

	.seventhpage_container {
		zoom: 50%;
	}

	.seventhpage_middle_item {
		zoom: 70%;
	}

	.mainfooter {
		zoom: 30%;
	}

	.mainfooter_footer_container {
		zoom: 65%;
	}

	.mainfooter_drop_container {
		zoom: 90%;
	}
}

@media only screen and (max-width:340px) {
	.header_list_burger {
		display: block;
	}

	.header_list_noburger {
		display: none;
	}

	.header {
		zoom: 50%;
	}

	.firstpage {
		height: 55vh;
	}

	.firstpage_container {
		zoom: 38%;
	}

	.firstpage_left {
		margin-right: 0px;
	}

	.firstpage_right {
		background-size: 90%;
	}

	.secondpage {
		zoom: 40%;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 1fr);
	}

	.i1 {
		grid-row: 1 / span 1;
	}

	.i3 {
		grid-column: 1 / span 2;
	}

	.secondpage_item {
		width: 100%;
	}

	.secondpage_item_container {
		padding: 120px;
		zoom: 80%;
	}

	.thirdpage {
		height: 80vh;
	}

	.thirdpage_container {
		zoom: 40%;
		margin-left: -100px;
	}

	.thirdpage_container_decimage {
		display: none;
	}


	.thirdpage_container_main_left {
		transform: rotate(-90deg);
		margin-top: 80px;
	}

	.thirdpage_container {
		margin-left: 0px;
	}

	.thirdpage_container_main {
		margin-right: 0px;
		margin-bottom: 100px;
	}

	.thirdpage_container_banner {
		margin-bottom: 0px;
	}

	.fourthpage {
		height: 100vh;
	}

	.fourthpage_container {
		zoom: 45%;
	}

	.fourthpage_bot {
		flex-wrap: wrap;
	}

	.fourthpage_bot_item {
		max-width: 500px;
	}

	.fifthpage {
		height: 45vh;
	}

	.fifthpage_container {
		zoom: 40%;
	}

	.social_container {
		zoom: 30%;
	}

	.social_box {
		flex-wrap: wrap;
		margin-top: 50px;
	}

	.social_box_item {
		margin-top: 15px;
	}

	.fifthpage_slider_item {
		zoom: 50%;
	}

	.sixthpage {
		height: 50vh;
	}

	.sixthpage_container {
		zoom: 40%;
	}

	.sixthpage_slider {
		zoom: 60%;
	}

	.seventhpage_container {
		zoom: 50%;
	}

	.seventhpage_middle_item {
		zoom: 70%;
	}

	.mainfooter {
		zoom: 30%;
	}

	.mainfooter_footer_container {
		zoom: 65%;
	}

	.mainfooter_drop_container {
		zoom: 90%;
	}
}

@media only screen and (max-width:300px) {
	.header_list_burger {
		display: block;
	}

	.header_list_noburger {
		display: none;
	}

	.header {
		zoom: 50%;
	}

	.firstpage {
		height: 55vh;
	}

	.firstpage_container {
		zoom: 30%;
	}

	.firstpage_left {
		margin-right: 0px;
	}

	.firstpage_right {
		background-size: 90%;
	}

	.secondpage {
		zoom: 30%;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 1fr);
	}

	.i1 {
		grid-row: 1 / span 1;
	}

	.i3 {
		grid-column: 1 / span 2;
	}

	.secondpage_item {
		width: 100%;
	}

	.secondpage_item_container {
		padding: 120px;
		zoom: 80%;
	}

	.thirdpage {
		height: 80vh;
	}

	.thirdpage_container {
		zoom: 30%;
		margin-left: -100px;
	}

	.thirdpage_container_decimage {
		display: none;
	}


	.thirdpage_container_main_left {
		transform: rotate(-90deg);
		margin-top: 80px;
	}

	.thirdpage_container {
		margin-left: 0px;
	}

	.thirdpage_container_main {
		margin-right: 0px;
		margin-bottom: 100px;
	}

	.thirdpage_container_banner {
		margin-bottom: 0px;
	}

	.fourthpage {
		height: 100vh;
	}

	.fourthpage_container {
		zoom: 35%;
	}

	.fourthpage_bot {
		flex-wrap: wrap;
	}

	.fourthpage_bot_item {
		max-width: 500px;
	}

	.fifthpage {
		height: 45vh;
	}

	.fifthpage_container {
		zoom: 30%;
	}

	.social_container {
		zoom: 30%;
	}

	.fifthpage_slider_item {
		zoom: 50%;
	}

	.sixthpage {
		height: 50vh;
	}

	.sixthpage_container {
		zoom: 40%;
	}

	.sixthpage_slider {
		zoom: 60%;
	}

	.seventhpage_container {
		zoom: 40%;
	}

	.seventhpage_middle_item {
		zoom: 70%;
	}

	.mainfooter {
		zoom: 25%;
	}

	.mainfooter_footer_container {
		zoom: 65%;
	}

	.mainfooter_drop_container {
		zoom: 90%;
	}
}

@supports (-webkit-touch-callout: none) {
	//iphone only

	.seventhpage_middle_item {
		zoom: 125%;
	}

	.fifthpage{
		height: auto;
        padding-top: 100px;
        padding-bottom: 100px;
	}

	.fifthpage_slider_item_button {
		zoom: 125%;
	}

	.social_box_item_container {
		zoom: 90%;
	}

	.fifthpage_projects_container{
		height: 40vh;
	}

	.mainfooter {
		zoom: 45%;
	}

	.mainfooter_footer_container {
		zoom: 30%;
	}

	.mainfooter_footer_goback {
		display: none;
	}

	.mainfooter_footer_news {
		display: none;
	}

	.mainfooter_footer_pages {
		margin-right: 0px;
		margin-left: 200px;
	}

	.servicepage_item_infocontainer_button {
		display: none;
	}

	.socialpage{
		height: 100vh;
	}

	.k1 {
		display: none;
	}

	.k2 {
		display: none;
	}

	.k3 {
		display: none;
	}

	.k4 {
		display: none;
	}

	.mainfooter_drop_container_middle_item_decline {
		display: none;
	}

	.mainfooter_footer_contact_item_icon1 {
		zoom: 200%;
	}

	.mainfooter_footer_contact_item_icon2 {
		zoom: 200%;
	}

	.mainfooter_footer_contact_item_icon3 {
		zoom: 200%;
	}

	.mainfooter_footer_contact_item_icon4 {
		zoom: 200%;
	}

}