body, html {
  margin: 0;
  background-color: #1f1f3b;
  overflow-x: hidden;
  position: relative;
  cursor: none ;
}
.slick-prev::before,.slick-next::before{
  display: none !important;
}

.slick-next{
  z-index: 3;
  margin-left: -25px !important;
  right: 0px !important;
  cursor: none !important;
}
.slick-prev{
  z-index: 3;
  left: 0px !important;
  cursor: none !important;

}
.slick-dots{
  display: none !important;
}

:root {
  --color-cursor: 112, 79, 195;
  --cursor-outline-shade: 0.3;
  --cursor-size: 10px;
  --cursor-outline-size: 12px;
}

#cursor-dot,
#cursor-dot-outline {
  z-index: 999;
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
}

#cursor-dot {
  width: var(--cursor-size);
  height: var(--cursor-size);
  background-color: rgba(var(--color-cursor), 1);
}

#cursor-dot-outline {
  width: var(--cursor-outline-size);
  height: var(--cursor-outline-size);
  background-color: rgba(var(--color-cursor), var(--cursor-outline-shade));
}

.slick-slide {
 margin: 0px;
}

.slick-list {
  margin: 0px;
}

:root {
  --animate-duration: 1.4s !important;
}




